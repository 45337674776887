/** @jsxImportSource theme-ui */
import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import { PrismicRichText } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// markup
const ProjectListPage = ({ data }) => {
  const projectList = data.allPrismicProjects.edges;
  const pageData = data.prismicProjectsPage.data;
  console.log("projectlist", projectList);
  //const currentBlog = blogList.splice(0, 1);
  //const currentBlog = blogList[0];

  //console.log("blogList", blogList);
  //console.log("currentBlog", currentBlog);

  //const currentBlogImage = getImage(currentBlog.node.data.image);
  return (
    <Layout>
      <SEO
        title="Portrait Photography Projects"
        description="London headshot and portrait photographer. Professional headshots for actors. Photographic ramblings about photography, acting, headshots"
      />
      <div sx={BlogStyles}>
        <div sx={{ px: [3, 2, 1], mb: 4, p: { color: "muted" } }}>
          <h1>{pageData.title.text}</h1>
          <PrismicRichText field={pageData.text.richText} />
        </div>
      </div>
      <div className="otherPosts" sx={OtherPostStyles}>
        {projectList.map((item, index) => {
          const image = getImage(item.node.data.thumbnail);

          return (
            <Link
              to={`/project/${item.node.uid}`}
              key={`project${index}`}
              className="postItem"
            >
              <div className="postImage">
                {image ? (
                  <GatsbyImage
                    image={image}
                    alt={item.node.data.thumbnail.alt}
                  />
                ) : (
                  <StaticImage
                    src="../images/default-image.jpg"
                    alt=""
                    aspectRatio={1.78}
                  />
                )}
              </div>
              <h3>{item.node.data.title.text}</h3>
              {/* <h4>{item.node.data.shortDate}</h4> */}
              <p>{item.node.data.excerpt}</p>
            </Link>
          );
        })}
      </div>
    </Layout>
  );
};

export default withPrismicPreview(ProjectListPage);

export const query = graphql`
  query projectListPageQuery {
    allPrismicProjects(sort: { fields: data___date, order: DESC }, limit: 6) {
      edges {
        node {
          _previewable
          data {
            shortDate: date(formatString: "DD MMM YY")
            fulldate: date(formatString: "DD MMMM, YYYY")
            thumbnail {
              alt
              gatsbyImageData(aspectRatio: 1.78)
            }
            text {
              richText
              text
            }
            excerpt
            title {
              richText
              text
            }
          }
          last_publication_date
          uid
        }
      }
    }
    prismicProjectsPage {
      _previewable
      data {
        text {
          richText
          text
          raw
        }
        title {
          text
          richText
        }
      }
      last_publication_date
    }
  }
`;

const BlogStyles = {
  display: ["block", "flex"],
  justifyContent: "space-between",
  mb: 5,
  p: {
    fontSize: 3,
  },
  "& > div": {
    width: ["auto", "47%"],
  },
  "& .postItem": {
    textDecoration: "none",
    "& .postImage": {
      border: "1px solid black",
    },
    h3: {
      fontSize: 4,
      fontWeight: 700,
      mb: 0,
      mt: 2,
      color: "black",
    },
    h4: {
      mt: 1,
      fontSize: 3,
      mb: 2,
    },
    p: {
      fontSize: 3,
    },
  },
};

const OtherPostStyles = {
  display: ["block", "flex"],
  mb: 6,
  //justifyContent: "space-between",
  "& .postItem": {
    width: ["auto", "47%", "31%"],
    mx: "1%",
    textDecoration: "none",
    "& .postImage": {
      border: "1px solid black",
    },
    h3: {
      fontSize: 4,
      fontWeight: 700,
      mb: 0,
      mt: 2,
      color: "black",
    },
    h4: {
      mt: 1,
      fontSize: 3,
      mb: 2,
    },
    p: {
      fontSize: 3,
    },
  },
};
